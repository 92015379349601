import { useEffect, useState } from "react";
import { ModalTabs } from "../ModalTabs/ModalTabs";
import { fetchBranches, fetchCities } from "../../services/novaPoshta";
import { createInvoice, createInvoiceEng } from "../../services/monobank";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { useTranslation } from "react-i18next";
import { Sizes } from "../../interfaces/sizes";
import { toast } from "react-toastify";

export const CartModal = ({
  setOrder,
  order,
  setProcessing,
  userCart,
  exchangeRate,
  quantities,
  userCartSizes,
  total,
}: {
  setOrder: React.Dispatch<React.SetStateAction<string>>;
  order: string;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  userCart: any;
  exchangeRate: number;
  quantities: any;
  userCartSizes: Sizes[] | [];
  total: number;
}) => {
  const [activeTab, setActiveTab] = useState<"ukraine" | "abroad">("ukraine");
  const [bankPayment, setBankPayment] = useState(true);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [abroadCity, setAbroadCity] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [city, setCity] = useState("");
  const [branch, setBranch] = useState("");

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (i18n.language === "en") {
      setActiveTab("abroad");
    }
  }, []);

  const getAllCities = async (searchQuery: string) => {
    setCities(await fetchCities(searchQuery));
  };

  const getAllBranches = async (cityRef: any) => {
    setBranches(await fetchBranches(cityRef));
  };
  const handleCityChange = (e: any) => {
    setCity(e.target.value);
    setSelectedCity(null);
    setBranches([]);
    setFilteredBranches([]);
    if (e.target.value.length > 2) {
      getAllCities(e.target.value);
    } else {
      setCities([]);
    }
  };

  function calculateDeliveryTotal() {
    let total = 0;
    const cart = JSON.parse(localStorage.getItem("userCartLimits") ?? `{}`);

    if (cart.merch >= 1 && cart.merch <= 2) {
      total += 400;
    } else if (cart.merch >= 3 && cart.merch <= 4) {
      total += 750;
    } else if (cart.merch >= 5) {
      total += 900;
    }

    if (cart.vinyl >= 1 && cart.vinyl <= 2) {
      total += 1100;
    } else if (cart.vinyl >= 3 && cart.vinyl <= 4) {
      total += 1600;
    } else if (cart.vinyl >= 5) {
      total += 2000;
    }

    if (cart.cassette >= 1 && cart.cassette <= 2) {
      total += 650;
    } else if (cart.cassette >= 3 && cart.cassette <= 4) {
      total += 1000;
    } else if (cart.cassette >= 5) {
      total += 1650;
    }

    return total;
  }

  function calculateDeliveryTotalUsd() {
    let total = 0;
    const cart = JSON.parse(localStorage.getItem("userCartLimits") ?? `{}`);

    if (cart.merch >= 1 && cart.merch <= 2) {
      total += 10;
    } else if (cart.merch >= 3 && cart.merch <= 4) {
      total += 18;
    } else if (cart.merch >= 5) {
      total += 22;
    }

    if (cart.vinyl >= 1 && cart.vinyl <= 2) {
      total += 26;
    } else if (cart.vinyl >= 3 && cart.vinyl <= 4) {
      total += 38;
    } else if (cart.vinyl >= 5) {
      total += 48;
    }

    if (cart.cassette >= 1 && cart.cassette <= 2) {
      total += 15;
    } else if (cart.cassette >= 3 && cart.cassette <= 4) {
      total += 24;
    } else if (cart.cassette >= 5) {
      total += 40;
    }

    return total;
  }

  const handleCitySelect = (city: any) => {
    setCity(city.Description);
    setSelectedCity(city);
    setCities([]);
    setBranches([]);
    setBranch("");
    getAllBranches(city.Ref);
  };

  const handleBranchChange = (e: any) => {
    if (city && selectedCity) {
      if (selectedCity) {
        const inputValue = e.target.value;
        setBranch(inputValue);

        const filtered = branches.filter((branch: any) =>
          branch?.Description.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredBranches(filtered);
      }
    }
  };

  const handleBranchSelect = (branch: any) => {
    setBranch(branch.Description);
    setBranches([]);
    setFilteredBranches([]);
  };

  const countTotalAmount = () => {
    let total = 0;
    let index = 0;
    for (const { usd, uah } of userCart) {
      total +=
        uah !== 0
          ? uah * quantities[index]
          : roundToHigherOrder(usd * exchangeRate * quantities[index]);
      index += 1;
    }
    const res = roundToHigherOrder(Math.round(total));
    return res;
  };

  return (
    <div
      className="backdrop"
      id="backdrop"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        if (target?.id === "backdrop") {
          setOrder("");
        }
      }}
    >
      <div className="modal">
        <p className="modal__title" style={{ margin: 0 }}>
          {t("CartData")}
        </p>
        <div className="modal__thumb">
          <p className="modal__subtitle">{t("CartFullName")}:</p>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              const regex = /^[\u0400-\u04FFa-zA-Z\s]*$/;

              if (regex.test(e.target.value)) {
                setName(e.target.value);
              }
            }}
            className="modal__input"
            placeholder={t("CartFullName")}
          />
        </div>
        <div className="modal__thumb">
          <p className="modal__subtitle">{t("CartPhone")}</p>
          <input
            type="text"
            value={phone}
            onChange={(e) => {
              const inputValue = e.target.value;
              const sanitizedValue = inputValue.replace(/[^0-9]/g, "");

              if (activeTab === "ukraine") {
                if (
                  sanitizedValue.length === 0 ||
                  sanitizedValue.length <= 10
                ) {
                  setPhone(sanitizedValue);
                }
              } else {
                setPhone(sanitizedValue);
              }
            }}
            className="modal__input"
            placeholder={t("CartPhonePlaceholder")}
          />
        </div>
        <div className="modal__thumb">
          <p className="modal__subtitle">{t("CartEmail")}</p>
          <input
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="modal__input"
            placeholder={t("CartEmailPlaceholder")}
          />
        </div>
        <p className="modal__title">{t("CartDeliveryMethod")}</p>
        <ModalTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setBankPayment={setBankPayment}
        />
        {activeTab === "ukraine" ? (
          <>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartSettlement")}:</p>
              <div className="modal__select--wrapper">
                <input
                  type="text"
                  value={city}
                  onChange={handleCityChange}
                  className="modal__input"
                  placeholder={t("CartCityPlaceholder")}
                />
                {cities.length > 0 && (
                  <ul className="modal__select--list">
                    {cities.map((city: any) => (
                      <li
                        key={city?.Ref}
                        onClick={() => handleCitySelect(city)}
                        className="modal__select--item"
                      >
                        {city.Description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartBranch")}:</p>
              <div className="modal__select--wrapper">
                <input
                  type="branch"
                  value={branch}
                  onChange={handleBranchChange}
                  className="modal__input"
                  placeholder={t("CartBranchPlaceholder")}
                />
                {selectedCity && filteredBranches.length > 0 && (
                  <ul className="modal__select--list">
                    {filteredBranches?.map((branch: any) => (
                      <li
                        key={branch.Ref}
                        onClick={() => handleBranchSelect(branch)}
                        className="modal__select--item"
                      >
                        {branch.Description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartCountry")}:</p>
              <input
                type="text"
                value={country}
                onChange={(e) => {
                  const regex = /^[\u0400-\u04FFa-zA-Z\s]*$/;

                  if (regex.test(e.target.value)) {
                    if (e.target.value === " ") {
                    } else {
                      setCountry(e.target.value);
                    }
                  }
                }}
                className="modal__input"
                placeholder={t("CartCountryPlaceholder")}
              />
            </div>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartAbroadCity")}:</p>
              <input
                type="text"
                value={abroadCity}
                onChange={(e) => {
                  const regex = /^[\u0400-\u04FFa-zA-Z\s]*$/;

                  if (regex.test(e.target.value)) {
                    if (e.target.value === " ") {
                    } else {
                      setAbroadCity(e.target.value);
                    }
                  }
                }}
                className="modal__input"
                placeholder={t("CartAbroadCityPlaceholder")}
              />
            </div>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartAddress")}:</p>
              <input
                type="text"
                value={street}
                onChange={(e) => {
                  if (e.target.value === " ") {
                  } else {
                    setStreet(e.target.value);
                  }
                }}
                className="modal__input"
                placeholder={t("CartAddressPlaceholder")}
              />
            </div>
            <div className="modal__thumb">
              <p className="modal__subtitle">{t("CartPostcode")}:</p>
              <input
                type="text"
                value={zip}
                onChange={(e) => {
                  if (e.target.value === " ") {
                  } else {
                    setZip(e.target.value);
                  }
                }}
                className="modal__input"
                placeholder={t("CartPostcodePlaceholder")}
              />
            </div>
          </>
        )}
        <p className="modal__title">{t("CartPaymentMethod")}</p>
        {activeTab === "ukraine" ? (
          <>
            <div className="modal__payment">
              <div
                onClick={() => {
                  setBankPayment(true);
                }}
                className={
                  bankPayment
                    ? "modal__payment--box modal__payment--boxActive"
                    : "modal__payment--box"
                }
              ></div>
              <p className="modal__subtitle" style={{ display: "block" }}>
                {t("CartPaymentMethodDescriptionFirst")}
              </p>
            </div>
            <div className="modal__payment">
              <div
                onClick={() => {
                  setBankPayment(false);
                }}
                className={
                  bankPayment
                    ? "modal__payment--box"
                    : "modal__payment--box modal__payment--boxActive"
                }
              ></div>
              <p className="modal__subtitle" style={{ display: "block" }}>
                {t("CartPaymentMethodDescriptionSecond")}
              </p>
            </div>
          </>
        ) : (
          <p className="modal__subtitle" style={{ display: "block" }}>
            {t("CartPaymentMethodDescriptionThird")}
            {i18n.language === "uk"
              ? `${calculateDeliveryTotal()} ₴`
              : `${calculateDeliveryTotalUsd()} $`}
          </p>
        )}

        <div className="modal__line"></div>
        <p className="modal__total">
          {t("CartInTotal")}:{" "}
          <span className="modal__total--accent">
            {activeTab === "ukraine"
              ? total
              : activeTab === "abroad" && i18n.language === "uk"
              ? total + calculateDeliveryTotal()
              : total + calculateDeliveryTotalUsd()}
            {t("Currency")}
          </span>
        </p>
        <button
          className="modal__button button__hovering"
          onClick={async () => {
            if (name && phone && email) {
              if (
                (activeTab === "ukraine" && city && branch) ||
                (activeTab === "abroad" &&
                  abroadCity &&
                  country &&
                  street &&
                  zip)
              ) {
                const products: any = [];
                let index = 0;
                for (const product of userCart) {
                  const obj: any = {
                    id: product._id,
                    amount: quantities[index],
                    price:
                      product.language === "uk"
                        ? product.uah !== 0
                          ? roundToHigherOrder(product.uah)
                          : roundToHigherOrder(
                              product.usd * exchangeRate * quantities[index]
                            )
                        : product.usd,
                    title:
                      product.type === "merch"
                        ? `${product.name} - ${product.from}`
                        : `${product.artistName} - ${product.albumName}`,
                    availability: product?.availability,
                    type: product?.type,
                  };
                  if (product.type === "merch") {
                    obj.size =
                      product.info[0].oneSizeAmount > 0
                        ? ""
                        : userCartSizes[index];
                  }
                  products.push(obj);
                  index += 1;
                }
                const message =
                  `
НОВЕ ЗАМОВЛЕННЯ НА САЙТІ

ПІБ: ${name} 
E-mail: ${email} 
Номер телефону: ${phone}

Метод оплати: ${bankPayment ? "Банківський переказ" : "Наложенний платіж"}

Доставка:  
${
  activeTab === "abroad"
    ? `- Вартість: ${
        i18n.language === "uk"
          ? calculateDeliveryTotal()
          : calculateDeliveryTotalUsd()
      }`
    : ""
}
- Служба: ${activeTab === "abroad" ? "Укрпошта" : "Нова Пошта"} 
${activeTab === "abroad" ? `- Країна: ${country}` : ""}
${
  activeTab === "ukraine"
    ? `- Населений пункт: ${city}`
    : `- Населений пункт: ${abroadCity}`
}
${activeTab === "ukraine" ? `- Відділення: ${branch}` : ""}
${activeTab === "abroad" ? `- Вулиця: ${street}` : ""}
${activeTab === "abroad" ? `- Поштовий індекс: ${zip}` : ""}
Замовлення:  
` + order;
                if (activeTab === "ukraine") {
                  toast("Опрацьовуємо замовлення", {
                    className: "toast__success",
                    bodyClassName: "toast__body",
                    progressClassName: "toast__progressBar--success",
                  });
                  const data = await createInvoice(
                    `${countTotalAmount()}00`,
                    city,
                    branch,
                    bankPayment ? "Банківський переказ" : "Наложенний платіж",
                    products,
                    total,
                    name,
                    phone,
                    email,
                    message,
                    activeTab,
                    i18n.language === "uk"
                      ? calculateDeliveryTotal()
                      : calculateDeliveryTotalUsd(),
                    i18n.language
                  );
                  if (data) {
                    if (bankPayment) {
                      setProcessing(true);
                    } else {
                      toast("Замовлення успішне!", {
                        className: "toast__success",
                        bodyClassName: "toast__body",
                        progressClassName: "toast__progressBar--success",
                      });
                      setTimeout(() => {
                        setOrder("");
                        localStorage.removeItem("invoiceLink");
                        localStorage.removeItem("invoiceId");
                        localStorage.removeItem("userCartColors");
                        localStorage.removeItem("userCartId");
                        localStorage.removeItem("userCartIds");
                        localStorage.removeItem("userCartQuantities");
                        localStorage.removeItem("userCartSizes");
                        localStorage.removeItem("userCartTypes");
                        window.location.href = "/";
                      }, 1000);
                    }
                  }
                } else {
                  const data = await createInvoiceEng(
                    `${countTotalAmount() + calculateDeliveryTotal()}00`,
                    country,
                    abroadCity,
                    street,
                    zip,
                    bankPayment ? "Банківський переказ" : "Наложенний платіж",
                    products,
                    total,
                    name,
                    phone,
                    email,
                    message,
                    activeTab,
                    i18n.language === "uk"
                      ? calculateDeliveryTotal()
                      : calculateDeliveryTotalUsd(),
                    i18n.language
                  );
                  if (data) {
                    setProcessing(true);
                  }
                }
              }
            }
          }}
        >
          {t("CartPay")}
        </button>
      </div>
    </div>
  );
};
